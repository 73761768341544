import { MhcPageFragment, MhcTopicCategoryEnum, MhcTopicTypeEnum } from "graphqlApi/types";

import { PickerItem } from "modules/Resources/indicator-browser/components/Picker";

export const isOptionEqualToValue = (a: PickerItem, b: PickerItem) => a?.id === b?.id;

export const indicatorBrowserTopic: MhcPageFragment = {
  __typename: "MhcTopic",
  category: MhcTopicCategoryEnum.Reference,
  href: "/portals/indicator-browser",
  id: "indicator-browser",
  name: "Indicator Browser",
  slug: "indicator-browser",
  topicType: MhcTopicTypeEnum.Topic
};

export const determineLabel = ({ id, name }: PickerItem) => {
  let optionName = name;
  if (!id) return optionName;
  switch (true) {
    case id.includes("COUNT"):
      optionName += " (Count)";
      break;
    case id.includes("CRUDE_RATE"):
      optionName += " (Rate)";
      break;
    case id.includes("RATE"):
      optionName += " (Age-Adjusted Rate)";
      break;
  }
  return optionName;
};
